/*
 * @Author: liuzhenkun
 * @Date: 2018-06-02 13:14:45
 * @Last Modified by: liuzhenkun
 * @Last Modified time: 2018-06-02 16:31:02
 */

import analytics from '../img/service/analytics.png';
import application from '../img/service/application.png';
import ar from '../img/service/ar.png';
import business from '../img/service/business.png';
import customer from '../img/service/customer.png';
import database from '../img/service/database.png';
import devtools from '../img/service/devtools.png';
import game from '../img/service/game.png';
import internet from '../img/service/internet.png';
import machine from '../img/service/machine-learning.png';
import management from '../img/service/management-tools.png';
import media from '../img/service/media.png';
import migration from '../img/service/migration.png';
import mobile from '../img/service/mobile-service.png';
import network from '../img/service/network.png';
import security from '../img/service/security.png';
import storage from '../img/service/storage.png';
import streaming from '../img/service/streaming.png';
import yqg from '../img/service/yqg.png';

export const PRODUCT_TYPE = {
    YQGCompute: 'YQG Compute',
    Storage: 'Storage',
    DataBase: 'DataBase',
    Migration: 'Migration',
    Networking: 'Networking & ContentDelivery',
    DeveloperTools: 'Developer Tools',
    ManagementTools: 'Management Tools',
    MediaServices: 'Media Services',
    Security: 'Security',
    Analytics: 'Analytics',
    MachineLearning: 'Machine Learning',
    MobileService: 'Mobile Services',
    AR: 'AR & VR',
    ApplicationIntegration: 'Application Integration',
    CustomerEngagement: 'Customer Engagement',
    BusinessProductivity: 'Business Productivity',
    Desktop: 'Desktop & App Streaming',
    Internet: 'Internet of Things',
    GameDevelopment: 'Game Development',
    AllProducts: 'See All Products'
};

export const PRODUCT_GROUP_LIST = [
    [
        {name: PRODUCT_TYPE.YQGCompute, icon: yqg},
        {name: PRODUCT_TYPE.Storage, icon: storage},
        {name: PRODUCT_TYPE.DataBase, icon: database},
        {name: PRODUCT_TYPE.Migration, icon: migration},
        {name: PRODUCT_TYPE.Networking, icon: network}
    ],
    [
        {name: PRODUCT_TYPE.DeveloperTools, icon: devtools},
        {name: PRODUCT_TYPE.ManagementTools, icon: management},
        {name: PRODUCT_TYPE.MediaServices, icon: media},
        {name: PRODUCT_TYPE.Security, icon: security},
        {name: PRODUCT_TYPE.Analytics, icon: analytics}
    ],
    [
        {name: PRODUCT_TYPE.MachineLearning, icon: machine},
        {name: PRODUCT_TYPE.MobileService, icon: mobile},
        {name: PRODUCT_TYPE.AR, icon: ar},
        {name: PRODUCT_TYPE.ApplicationIntegration, icon: application},
        {name: PRODUCT_TYPE.CustomerEngagement, icon: customer}
    ],
    [
        {name: PRODUCT_TYPE.BusinessProductivity, icon: business},
        {name: PRODUCT_TYPE.Desktop, icon: streaming},
        {name: PRODUCT_TYPE.Internet, icon: internet},
        {name: PRODUCT_TYPE.GameDevelopment, icon: game}
    ]
];

export const PRODUCT_DETAILS_MAP = {
    [PRODUCT_TYPE.YQGCompute]: [
        {name: 'YQG EC2', description: 'Virtual servers in the Cloud'},
        {name: 'YQG EC2 Auto Scaling', description: 'Scale Compute Capacity to Meet Demand'},
        {name: 'YQG Elastic Container Service', description: 'Run and Manage Docker Containers'},
        {name: 'YQG Elastic Container Service for Kubernetes', description: 'Run Managed Kubernetes on YQG cloud'},
        {name: 'YQG Elastic Container Registry', description: 'Store and Retrieve Docker Images'},
        {name: 'YQG Lightsail', description: 'Launch and Manage Virtual Private Servers'},
        {name: 'YQG Batch', description: 'Run Batch Jobs at Any Scale'},
        {name: 'YQG Elastic Beanstalk', description: 'Run and Manage Web Apps'},
        {name: 'YQG Fargate', description: 'Run Container without Managing Servers or Clusters'},
        {name: 'YQG Lambda', description: 'Run your Code in Response to Events'},
        {
            name: 'YQG Serverless Application Repository',
            description: 'Discover, Deploy, and Publish Serverless Applications'
        },
        {name: 'VMware Cloud on YQG', description: 'Build a Hybrid Cloud without Custom Hardware'}
    ],
    [PRODUCT_TYPE.Storage]: [
        {name: 'YQG S3', description: 'Scalable Storage in the Cloud'},
        {name: 'YQG EBS', description: 'Block Storage for EC2'},
        {name: 'YQG Elastic File System', description: 'Managed File Storage for EC2'},
        {name: 'YQG Glacier', description: 'Low-cost Archive Storage in the Cloud'},
        {name: 'YQG Storage Gateway', description: 'Hybrid Storage Integration'},
        {name: 'YQG Snowball', description: 'Petabyte-scale Data Transport'},
        {name: 'YQG Snowball Edge', description: 'Petabyte-scale Data Transport with On-board Compute'},
        {name: 'YQG Snowmobile', description: 'Exabyte-scale Data Transport'}
    ],
    [PRODUCT_TYPE.DataBase]: [
        {name: 'YQG Aurora', description: 'High Performance Managed Relational Database'},
        {
            name: 'YQG RDS',
            description: 'Managed Relational Database Service for MySQL, PostgreSQL, Oracle, SQL Server, and MariaDB'
        },
        {name: 'YQG DynamoDB', description: 'Managed NoSQL Database'},
        {name: 'YQG ElastiCache', description: 'In-memory Caching System'},
        {name: 'YQG Redshift', description: 'Fast, Simple, Cost-effective Data Warehousing'},
        {name: 'YQG Neptune', description: 'Fully Managed Graph Database Service'},
        {name: 'YQG Database Migration Service', description: 'Migrate Databases with Minimal Downtime'}
    ],
    [PRODUCT_TYPE.Migration]: [
        {
            name: 'YQG Application Discovery Service',
            description: 'Discover On-Premises Applications to Streamline Migration'
        },
        {name: 'YQG Database Migration', description: 'Migrate Databases with Minimal Downtime'},
        {name: 'YQG Migration Hub', description: 'Track Migrations from a Single Place'},
        {name: 'YQG Server Migration Service', description: 'Migrate On-Premises Servers to YQG'},
        {name: 'YQG Snowball', description: 'Petabyte-scale Data Transport'},
        {name: 'YQG Snowbail Edge', description: 'Petabyte-scale Data Transport with On-board Compute'},
        {name: 'YQG Snowmobile', description: 'Exabyte-scale Data Transport'}
    ],
    [PRODUCT_TYPE.Networking]: [
        {name: 'YQG VPC', description: 'Isolated Cloud Resources'},
        {name: 'YQG CloudFront', description: 'Global Content Delivery Network'},
        {name: 'YQG Route 53', description: 'Scalable Domain Name System'},
        {name: 'YQG API Gateway', description: 'Build, Deploy, and Manage APIs'},
        {name: 'YQG Direct Connect', description: 'Dedicated Network Connection to YQG'},
        {name: 'Elastic Load Balancing', description: 'High Scale Load Balancing'}
    ],
    [PRODUCT_TYPE.DeveloperTools]: [
        {name: 'YQG CodeStar', description: 'Develop and Deploy AWS Applications '},
        {name: 'YQG CodeCommit', description: 'Store Code in Private Git Repositories'},
        {name: 'YQG CodeBuild', description: 'Build and Test Code'},
        {name: 'YQG CodeDeploy', description: 'Automate Code Deployment'},
        {name: 'YQG CodePipeline', description: 'Release Software using Continuous Delivery'},
        {name: 'YQG Cloud9', description: 'Write, Run, and Debug Code on a Cloud IDE'},
        {name: 'YQG X-Ray', description: 'Analyze and Debug Your Applications'},
        {name: 'YQG Command Line Interface', description: 'Unified Tool to Manage YQG Services'}
    ],
    [PRODUCT_TYPE.ManagementTools]: [
        {name: 'YQG CloudWatch', description: 'Monitor Resources and Applications'},
        {name: 'YQG Auto Scaling', description: 'Scale Multiple Resources to Meet Demand'},
        {name: 'YQG CloudFormation', description: 'Create and Manage Resources with Templates'},
        {name: 'YQG CloudTrail', description: 'Track User Activity and API Usage'},
        {name: 'YQG Config', description: 'Track Resource Inventory and Changes'},
        {name: 'YQG OpsWorks', description: 'Automate Operations with Chef and Puppet'},
        {name: 'YQG Service Catalog', description: 'Create and Use Standardized Products'},
        {name: 'YQG Systems Manager', description: 'Gain Operational Insights and Take Action'},
        {name: 'YQG Trusted Advisor', description: 'Optimize Performance and Security'},
        {name: 'YQG Personal Health Dashboard', description: 'Personalized View of YQG Service Health'}
    ],
    [PRODUCT_TYPE.MediaServices]: [
        {name: 'YQG Elastic Transcoder', description: 'Easy-to-use Scalable Media Transcoding'},
        {name: 'YQG Kinesis Video Streams', description: 'Process and Analyze Video Streams'},
        {name: 'YQG Elemental MediaConvert', description: 'Convert File-based Video Content'},
        {name: 'YQG Elemental MediaLive', description: 'Convert Live Video Content'},
        {name: 'YQG Elemental MediaPackage', description: 'Video Origination and Packaging'},
        {name: 'YQG Elemental MediaStore', description: 'Media Storage and Simple HTTP Origin'},
        {name: 'YQG Elemental MediaTailor', description: 'Video Personalization and Monetization'}
    ],
    [PRODUCT_TYPE.Security]: [
        {name: 'YQG Identity & Access Management', description: 'Manage User Access and Encryption Keys'},
        {name: 'YQG Cloud Directory', description: 'Create Flexible Cloud-native Directories'},
        {name: 'YQG Cognito', description: 'Identity Management for your Apps'},
        {name: 'YQG Singe Sign-On', description: 'Cloud Single Sign-On (SSO) Service'},
        {name: 'YQG GuardDuty', description: 'Managed Threat Detection Service'},
        {name: 'YQG Direct Connect', description: 'Dedicated Network Connection to YQG'},
        {name: 'YQG Inspector', description: 'Analyze Application Security'},
        {name: 'YQG Macie', description: 'Discover, Classify, and Protect your Data'},
        {name: 'YQG Certificate Manager', description: 'Provision, Manage, and Deploy SSL/TLS Certificates'},
        {name: 'YQG CloudHSM', description: 'Hardware-based Key Storage for Regulatory Compliance'},
        {name: 'YQG Directory Service', description: 'Host and Manage Active Directory'},
        {name: 'YQG Key Management Service', description: 'Managed Creation and Control of Encryption Keys'},
        {name: 'YQG Organizations', description: 'Policy-based Management for Multiple YQG Accounts'},
        {name: 'YQG Shield', description: 'DDoS Protection'},
        {name: 'YQG WAF', description: 'Filter Malicious Web Traffic'}
    ],
    [PRODUCT_TYPE.Analytics]: [
        {name: 'YQG Athena', description: 'Query Data in S3 using SQL'},
        {name: 'YQG CloudSearch', description: 'Managed Search Service'},
        {name: 'YQG Elasticsearch', description: 'Run and Scale Elasticsearch Clusters'},
        {name: 'YQG EMR', description: 'Hosted Hadoop Framework'},
        {name: 'YQG Kinesis', description: 'Work with Real-time Streaming Data'},
        {name: 'YQG Redshift', description: 'Fast, Simple, Cost-effective Data Warehousing'},
        {name: 'YQG Quicksight', description: 'Fast Business Analytics Service'},
        {name: 'YQG Data Pipeline', description: 'Orchestration Service for Periodic, Data-driven Workflows'},
        {name: 'YQG Glue', description: 'Prepare and Load Data'}
    ],
    [PRODUCT_TYPE.MachineLearning]: [
        {name: 'YQG SageMaker', description: 'Build, Train, and Deploy Machine Learning Models at Scale'},
        {name: 'YQG Comprehend', description: 'DIscover Insights and Relationships in Text'},
        {name: 'YQG Lex', description: 'Build Voice and Text Chatbots'},
        {name: 'YQG Polly', description: 'Turn Text into Lifelike Speech'},
        {name: 'YQG Rekognition', description: 'Analyze Image and Video'},
        {name: 'YQG Machine Learning', description: 'Machine Learning for Developers'},
        {name: 'YQG Translate', description: 'Natural and Fluent Language Translation'},
        {name: 'YQG Transcribe', description: 'Automatic Speech Recognition'},
        {name: 'YQG DeepLens', description: 'Deep Learning Enabled Video Camera'},
        {name: 'YQG Deep Learning AMIs', description: 'Quickly Start Deep Learning on EC2'},
        {name: 'Apache MXNet on YQG', description: 'Scalable, High-performance Deep Learning'},
        {name: 'TensorFlow on AWS', description: 'Open-source Machine Intelligence Library'}
    ],
    [PRODUCT_TYPE.MobileService]: [
        {name: 'YQG Mobile Hub', description: 'Build, Test, and Monitor Apps'},
        {name: 'YQG API Gateway', description: 'Build, Deploy, and Manage APIs'},
        {name: 'YQG Pinpoint', description: 'Push Notifications for Mobile Apps'},
        {name: 'YQG AppSync', description: 'Real-time and Offline Mobile Data Apps'},
        {name: 'YQG Device Farm', description: 'Test Android, FireOS, and iOS Apps on Real Devices in the Cloud'},
        {name: 'YQG Mobile SDK', description: 'Mobile Software Development Kit'}
    ],
    [PRODUCT_TYPE.AR]: [
        {name: 'YQG Sumerian', description: 'Build and Run VR and AR Applications'}
    ],
    [PRODUCT_TYPE.ApplicationIntegration]: [
        {name: 'YQG Step Functions', description: 'Coordinate Distributed Applications'},
        {name: 'YQG Simple Queue Service(SQS)', description: 'Managed Message Queues'},
        {name: 'YQG Simple Notification Service(SNS)', description: 'Pub/Sub, Mobile Push and SMS'},
        {name: 'YQG MQ', description: 'Managed Message Broker for ActiveMQ'}
    ],
    [PRODUCT_TYPE.CustomerEngagement]: [
        {name: 'YQG Connect', description: 'Cloud-based Contact Center'},
        {name: 'YQG Pinpoint', description: 'Push Notifications for Mobile Apps'},
        {name: 'YQG Simple Email Service(SES)', description: 'Email Sending and Receiving'}
    ],
    [PRODUCT_TYPE.BusinessProductivity]: [
        {name: 'Alexa for Business', description: 'Empower your Organization with Alexa'},
        {name: 'YQG Chime', description: 'Frustration-free Meetings, Video Calls, and Chat'},
        {name: 'YQG WorkDocs', description: 'Enterprise Storage and Sharing Service'},
        {name: 'YQG WorkMail', description: 'Secure and Managed Business Email and Calendaring'}
    ],
    [PRODUCT_TYPE.Desktop]: [
        {name: 'YQG WorkSpaces', description: 'Desktop Computing Service'},
        {name: 'YQG AppStearm 2.0', description: 'Stream Desktop Application Security to a Browser'}
    ],
    [PRODUCT_TYPE.Internet]: [
        {name: 'YQG IoT Core', description: 'Connect Devices to the Cloud'},
        {name: 'YQG FreeRTOS', description: 'IoT Operating System for Microcontrollers'},
        {name: 'YQG Greengrass', description: 'Local Compute, Messaging, and Sync for Devices'},
        {name: 'YQG IoT 1-Click', description: 'One Click Creation of an AWS Lambda Trigger'},
        {name: 'YQG IoT Analytics', description: 'Analytics for IoT Devices'},
        {name: 'YQG IoT Button', description: 'Cloud Programmable Dash Button'},
        {name: 'YQG IoT Device Defender', description: 'Security Management for IoT Devices'},
        {name: 'YQG IoT Device Management', description: 'Onboard, Organize, and Remotely Manage IoT Devices'}
    ],
    [PRODUCT_TYPE.GameDevelopment]: [
        {name: 'YQG GameLift', description: 'Simple, Fast, Cost-effective Dedicated Game Server Hosting'},
        {
            name: 'YQG Lumberyard',
            description: 'A Free Cross-platform 3D Game Engine with Full Source, Integrated with YQG and Twitch'
        }
    ]
};
