var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "club" }, [
    _c("header", [
      _c(
        "nav",
        { staticClass: "container" },
        [
          _c("router-link", { attrs: { to: { name: "index" } } }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("./img/logo.png") },
            }),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "nav-list" }, [
            _c(
              "li",
              { staticClass: "active" },
              [
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _vm._v("Home"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: { path: "/index#products" } } },
                  [_vm._v("Our Products")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { on: { click: _vm.connect } }, [
              _vm._v("\n                    Contact Us\n                "),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("img", {
        staticClass: "contact-btn",
        attrs: { src: require("./img/contact-btn.png") },
        on: { click: _vm.connect },
      }),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "product-part container", attrs: { id: "products" } },
      [
        _c("h3", [_vm._v("Product and Services")]),
        _vm._v(" "),
        _vm._l(_vm.productList, function (productGroup, groupIndex) {
          return _c(
            "div",
            { staticClass: "product-list" },
            [
              _c(
                "div",
                { staticClass: "product-group" },
                _vm._l(productGroup, function (product, productIndex) {
                  return _c(
                    "div",
                    {
                      staticClass: "product",
                      on: {
                        click: function ($event) {
                          return _vm.switchProduct(
                            product.name,
                            groupIndex,
                            productIndex
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "product-icon",
                        attrs: { src: product.icon },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "product-name" }, [
                        _vm._v(_vm._s(product.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.currentProduct.groupIndex === groupIndex
                  ? _c(
                      "div",
                      { staticClass: "product-details" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "triange",
                            class: _vm.triangePositionClass,
                          },
                          [
                            _c("div", { staticClass: "triange-outer" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "triange-inner" }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.productDetailsMap[_vm.currentProduct.name],
                          function (productDetail) {
                            return _c("div", { staticClass: "detail-item" }, [
                              _c("p", { staticClass: "detail-name" }, [
                                _vm._v(_vm._s(productDetail.name)),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "detail-description" }, [
                                _vm._v(_vm._s(productDetail.description)),
                              ]),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "footer-content container" }, [
        _c("img", {
          staticClass: "footer-icon",
          attrs: { src: require("./img/footer-icon.png") },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "footer-info" }, [
          _vm._v("Copyright © , Fintopia Inc. All Rights Reserved."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }