/**
 * @author zhenkunliu
 * @email zhenkunliu@yangqianguan.com
 * @date 2018-06-02 13:11:57.055
 * @desc generated by yqg-cli@0.1.12
 */

import {EASEMOB} from 'moneycan/common/constant/config';

import {connect} from 'ssr-common/vue/component/easemob/easemob';

import {PRODUCT_TYPE, PRODUCT_GROUP_LIST, PRODUCT_DETAILS_MAP} from './constant/product-config';

export default {
    name: 'Club',

    title: 'Fintopia Cloud',

    head() {
        return {};
    },

    data() {
        return {
            currentProduct: {
                groupIndex: 0,
                name: PRODUCT_TYPE.YQGCompute
            },
            productList: PRODUCT_GROUP_LIST,
            productDetailsMap: PRODUCT_DETAILS_MAP,
            triangePositionClass: 'triange-position-0'
        };
    },

    methods: {
        connect: () => connect(EASEMOB),

        switchProduct(productName, groupIndex, productIndex) {
            const vm = this;

            vm.currentProduct = {
                groupIndex,
                name: productName
            };

            vm.triangePositionClass = `triange-position-${productIndex}`;
        }
    }
};
